<template>
	<div class="bg-F7F7F7 paddt20 center">
		<div class="w1200 bg-white">
			<div class="paddlr20">
				<div class="paddt20">
					<div class="justify-content-between">
						<span class="fz20 co-333333">提交订单</span>
						<div>
							<div class="justify-content-center-between speed-line relative">
								<div class="h30 align-items-center relative">
									<div class="speed-left  center"
										:class="speedIndex == 1 ? 'bg-DCFF03' : 'bg-F3FFAA'"></div>
									<div class="center absolute left0 right0 top0 bottom0">
										<div class="speed-num fz20 font-blod bg-F3FFAA center co-1013305 boderra50">1
										</div>
									</div>
								</div>
								<div class="absolute top0 left0 bottom0 right0 center"
									:class="speedIndex == 2 ? 'z100' : 'z0'">
									<div class="h30 align-items-center relative">
										<div class="speed-left center"
											:class="speedIndex == 2 ? 'bg-DCFF03' : 'bg-F3FFAA'"></div>
										<div class="center absolute left0 right0 top0 bottom0">
											<div class="speed-num fz20 font-blod center boderra50"
												:class="speedIndex == 2 ? 'bg-DCFF03 co-010085' : 'bg-F3FFAA co-1013305'">
												2</div>
										</div>
									</div>
								</div>
								<div class="h30 align-items-center relative">
									<div class="speed-left center" :class="speedIndex == 3 ? 'bg-DCFF03' : 'bg-D9D9D9'">
									</div>
									<div class="center absolute left0 right0 top0 bottom0">
										<div class="speed-num fz20 font-blod center boderra50"
											:class="speedIndex == 3 ? 'bg-DCFF03 co-010085' : 'bg-D9D9D9 co-white'">3
										</div>
									</div>
								</div>
							</div>
							<div class="fz14 justify-content-center-between mart8">
								<div class="flex-1 center" :class="speedIndex == 1 ? 'co-333333' : 'co-CCCCCC'">1.购买会员
								</div>
								<div class="flex-1 center" :class="speedIndex == 2 ? 'co-333333' : 'co-CCCCCC'">
									2.填写核对订单信息</div>
								<div class="flex-1 center" :class="speedIndex == 3 ? 'co-333333' : 'co-CCCCCC'">3.提交订单成功
								</div>
							</div>
						</div>
					</div>
					<div v-if="speedIndex == 2">
						<div class="mart20 paddt15">
							<div class="justify-content-center-between">
								<div class="fz16 co-333333">收货地址</div>
								<div class="co-010085 fz16 cure-pointer" @click="Increaseaddress(1)">新增收货地址</div>
							</div>
							<div class="mart14" v-if="address.name">
								<div class="h40 justify-content-center-between marb10">
									<div class="">

										<span class="marr40">{{address.name}}</span>
										<span class="marr40">{{address.phone}}</span>
										<span>{{address.address}}</span>
									</div>
								</div>
							</div>
							<div class="address-box" :style="`height: ${showAddress ? '230px' : '0px'};`">
								<el-scrollbar height="230px">
									<div ref="element"
										class="h40 justify-content-center-between marb10 sure-address-item"
										v-for="(item, i) in list" :key="i" @click="chooseAddressInfo(i)">
										<div class="align-items-center">
											<img class="choose-icon display-block"
												:src="require(`@/assets/img/${iSelected == item.id ? 'is_choose' : 'no_choose'}.png`)" />
											<span class="fz14 co-333333 display-block marl19">{{item.name}}</span>
											<span class="fz14 co-333333 display-block marl46">{{item.phone}}</span>
											<span
												class="fz14 co-333333 display-block marl46">{{item.full_address}}</span>
										</div>
										<div class="align-items-center">
											<div class="set-up-box align-items-center fz14 co-010085">
												<span class="cure-pointer" v-if="item.is_default == 0"
													@click.stop="setUpDefaults(i,item.id)">设为默认地址</span>
												<span class="display-block marl46 cure-pointer"
													@click.stop="Increaseaddress(2,item)">编辑</span>
												<span class="marl46 display-block cure-pointer"
													@click.stop="delItem(i)">删除</span>
											</div>
											<div class="default-btn center fz12 co-010085 cure-pointer marl46"
												v-if="item.is_default == 1">默认地址</div>
										</div>
									</div>
								</el-scrollbar>
							</div>
						</div>

						<div class="paddt10 align-items-center paddb21 boderb1-E6E6E6 cure-pointer"
							@click="showAddress = !showAddress">
							<span class="fz14 co-999999">更多地址</span>
							<img class="address_pull display-block marl8" :class="showAddress ? 'rotate' : ''"
								src="@/assets/img/address_pull.png" />
						</div>

						<div class="paddt15 paddb21 boderb1-E6E6E6">
							<div class="fz16 co-333333 paddb20 font-blod">支付方式</div>
							<div class="align-items-center">
								<div class="pay-shoose center relative cure-pointer"
									:class="payWay == 1 ? 'pay-shoose-active' : ''" @click="payWay = 1">
									<img class="sub_02 display-block" src="@/assets/img/sub_02.png" alt="" />
									<span class="fz12 co-333333 display-block marl8">微信支付</span>
									<img class="display-block sub-03 absolute bottom0 right0" v-if="payWay == 1"
										src="@/assets/img/sub_03.png" alt="" />
								</div>

								<!-- <div class="pay-shoose center relative cure-pointer marl8"
									:class="payWay == 2 ? 'pay-shoose-active' : ''" @click="payWay = 2">
									<img class="sub_02 display-block" src="@/assets/img/sub_01.png" alt="" />
									<span class="fz12 co-333333 display-block marl8">支付宝支付</span>
									<img class="display-block sub-03 absolute bottom0 right0" v-if="payWay == 2"
										src="@/assets/img/sub_03.png" alt="" />
								</div> -->
							</div>
						</div>
						<div class="paddt15 paddb19 boderb1-E6E6E6">
							<div class="fz16 co-333333 paddb20 font-blod">会员</div>
							<div class="display-flex flex-wrap">
								<div class="single" v-for="(item, i) in centerInfo.infos" :key="i"
									@click="choose(i,item)">
									<div class="scroll_single flex-column relative" :class="{'active':conIndex == i}">
										<img class="single_cover" :src="item.image" alt="" />
										<div class="text-center fz16 paddt16">
											<div class="marb20 one-row title">{{item.title}}</div>
											<div class="align-items-center justify-content-center">
												<span class="fz2">￥</span>
												<span :class="{'co-010085':conIndex == i}"
													class="fz20 font-blod">{{item.price}}</span>
											</div>
										</div>
										<!-- <div class="tab_box">
											{{item.tip}}
										</div> -->
									</div>

								</div>
							</div>
						</div>
						<div class="justify-content-end align-items-center mart74 paddb17">
							<span class="fz16 co-333333">合计：</span>
							<span class="fz30 co-FE3000 display-block marl10 font-blod l30">￥{{price}}</span>
							<div class="cure-pointer submit-btn center fz18 co-010085 marl60" @click="submitOrder">提交订单
							</div>
						</div>
					</div>

					<div class="" v-if="speedIndex == 3">
						<div class="center mart137">
							<img class="sub_success display-block" src="@/assets/img/sub_success.png" alt="" />
						</div>
						<div class="fz20 co-333333 mart40 center ">订单提交成功</div>
						<div class="mart30   save-area center paddb417">
							<div class="center fz16 co-010085 btn-02 cure-pointer" @click="openshow">查看订单详情</div>
						</div>
					</div>
				</div>
			</div>
		</div>


		<!-- 确认取消弹窗 -->
		<SureCancelPopup ref="SureCancelPopup" @sureBtn="sureBtn"></SureCancelPopup>
		<el-dialog custom-class="boderra8" v-model="addressInfo" top="0" center @close='addclose'>
			<div class="fz20 co-333333 center paddt20 paddb20">{{fulltype == 1 ? '新增收货地址' : '修改收货地址'}}</div>
			<div class="center w100">
				<div class="align-items-center popup-name">
					<span class="fz16 co-FE3000">*</span>
					<span class="fz16 co-333333 marl4">收货人</span>
				</div>
				<div class="input-popup-area display-flex marl15">
					<input class="fz16 co-333333 flex-1 h100 input-popup" maxlength="6" v-model="name"
						placeholder="请输入收货人" />
				</div>
			</div>
			<div class="w100 mart20 display-flex justify-content-center">
				<div class="popup-name mart4">
					<span class="fz16 co-FE3000">*</span>
					<span class="fz16 co-333333 marl4">所在地区</span>
				</div>
				<div class="marl15">
					<div class="input-popup-area  fz16 co-999999 justify-content-center-between cure-pointer"
						@click="addresShow">
						<span
							:class="region.length == 0 ? 'co-999999' : 'co-333333'">{{region.length == 0 ? '请选择省/市/区' : `${region[0] || ''}${region[1] || ''}${region[2] || ''}`}}</span>
						<img class="address_pull display-block" :class="showAddresstow ? 'rotate' : ''"
							src="@/assets/img/address_pull.png" alt="" />
					</div>
					<div class="address-area-box mart2" :style="`height: ${showAddresstow ? '238px' : '0px'}`">
						<div class="address-area h100">
							<div class="justify-content-center-between">
								<div class="flex-1 h34 cure-pointer center"
									:class="addressShowType == 1 ? 'bg-white' : 'bg-E6E6E6'"
									@click="addressTypeChoose(1)">省份</div>
								<div class="flex-1 h34 cure-pointer center"
									:class="addressShowType == 2 ? 'bg-white' : 'bg-E6E6E6'"
									@click="addressTypeChoose(2)">城市</div>
								<div class="flex-1 h34 cure-pointer center"
									:class="addressShowType == 3 ? 'bg-white' : 'bg-E6E6E6'"
									@click="addressTypeChoose(3)">县区</div>
							</div>
							<div class="flex-1">
								<el-scrollbar height="204px">
									<div class="align-items-center h34 fz14 co-333333 align-items-center paddlr16 city-item"
										@click="chooseAddress(item,index)" v-for="(item, index) in addresslist"
										:key="index">{{item.name}}</div>
								</el-scrollbar>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="center w100 mart20">
				<div class="align-items-center popup-name">
					<span class="fz16 co-FE3000">*</span>
					<span class="fz16 co-333333 marl4">手机号</span>
				</div>
				<div class="input-popup-area display-flex marl15">
					<input type="number" class="fz16 co-333333 flex-1 h100 input-popup" maxlength="11" v-model="phone"
						placeholder="请输入手机号" />
				</div>
			</div>
			<div class="center w100 mart20">
				<div class="display-flex">
					<div class="align-items-center popup-name h100">
						<span class="fz16 co-FE3000">*</span>
						<span class="fz16 co-333333 marl4">详细地址</span>
					</div>
					<div class="marl15">
						<textarea class="fz16 co-333333 flex-1 textarea-popup" maxlength="50" v-model="addressDesc"
							placeholder="请输入详细地址"></textarea>
						<div class="mart13 align-items-center cure-pointer" @click="defaultChoose">
							<img class="is_choose display-block"
								:src="require(`@/assets/img/${setupDefault == 1 ? 'is_choose' : 'no_choose'}.png`)"
								alt="" />
							<span class="fz14 co-333333 display-block marl10">设为默认地址</span>
						</div>
						<div class="mart24 align-items-center paddb20">
							<div class="btn-popup-01 center cure-pointer fz14 co-010085" @click="save">保存</div>
							<div class="btn-popup-02 center cure-pointer fz14 co-333333 marl20" @click="cancel">取消</div>
						</div>
					</div>
				</div>
			</div>
		</el-dialog>
		<el-dialog :title="payWay==1?'微信支付':'支付宝支付'" @close="close" v-model="dialogFormVisible" :center='true'
			:width='700'>
			<div class="puops">
				<div class="qrcode-box" ref="payqrcode"></div>
				<p>请使用手机打开{{payWay==1?'微信':'支付宝'}}扫描二维码完成支付</p>
			</div>
		</el-dialog>
		<div v-loading.fullscreen.lock="fullscreenLoading"></div>
	</div>

</template>

<script>
	import {
		useRoute
	} from 'vue-router';
	import QRCode from 'qrcodejs2';
	import {
		getCurrentInstance,
		ref,
		reactive,
		watch
	} from 'vue';
	import {
		$on,
		$emit
	} from 'vue-happy-bus'
	export default {
		name: 'SubmitOrder',
		setup() {
			const req1 = getCurrentInstance();
			const request = req1.appContext.config.globalProperties;
			let WxLogInData = reactive({
				data: {
					appid: "",
					redirect_uri: encodeURIComponent(),
				}
			})
			return {
				request,
				WxLogInData,

			};
		},
		data() {
			return {
				dialogFormVisible: false, //微信支付弹框
				fullscreenLoading: false, //支付加载
				speedIndex: 2, // 进度
				list: [],
				showAddress: false, // 是否显示地址列表
				showAddresstow: false, // 获取地址
				payWay: 1, // 1微信支付 2支付宝支付
				conIndex: 0, // 优惠券选中
				addressInfo: false, // 填写地址弹窗是否显示
				showAddressPopup: false, // 是否显示地址列表
				addressShowType: 1, // 1省 2市 3区
				addresslist: [], // 省市区列表
				name: '', // 收货人姓名
				region: [], // 收货省市区
				phone: '', // 手机号
				addressDesc: '', // 详细地址
				setupDefault: 0, // 设置是否默认
				delIndex: -1, // 删除选中下标
				chooseIndex: 0, // 地址选中下标
				centerInfo: {},
				address: {
					id: "",
					address: '',
					phone: "",
					name: ""
				},
				price: '.00',
				iSelected: 0, // 是否选中
				areaId: {
					province_id: '',
					city_id: '',
					district_id: '',
				},
				provinceData: [],
				cityData: [],
				areaData: [],
				addressId: '', //修改的ID
				fulltype: 1, // 1填写地址 2修改地址
				order_id: '',
			}
		},
		created() {

		},
		mounted() {
			this.$req({
				method: 'get',
				url: '/api/vip/confirm',
				success: res => {
					if (res.address) {
						this.address = res.address

					}
					this.centerInfo = res.vip
					this.price = this.centerInfo.infos[0].price
					this.iSelected = this.address.id;

				},
				fail: error => {}
			});
			this.$req({
				method: 'get',
				data: {
					type: 1
				},
				url: '/api/maple/region',
				success: res => {
					let data = res;
					this.addresslist = data;
					this.provinceData = data;
				},
			});
			this.getData();


		},
		methods: {
			openshow() {
				this.$router.push({
					name: 'Myvip',
					params: {
						type: 1
					}
				})
			},
			choose(i, item) {
				this.conIndex = i;
				this.price = item.price
			},
			addclose() {
				this.showAddresstow = false;
				this.addresslist = []
				this.addressShowType = 1;
				this.region = [];
				this.areaId = {
					province_id: '',
					city_id: '',
					district_id: '',
				}
				this.$req({
					method: 'get',
					data: {
						type: 1
					},
					url: '/api/maple/region',
					success: res => {
						let data = res;
						this.addresslist = data;
						this.provinceData = data;
					},
				});

			},
			getData() {
				this.$req({
					method: 'get',
					data: {
						page: 1,
						size: 10,
					},
					url: '/api/maple/address',
					success: res => {
						let list = res;
						this.list = list
					},
				});
			},
			// 提交订单
			submitOrder() {
				// this.speedIndex = 3
				if (!this.address) {
					this.$message.error('请先选择收货地址')
					return
				}
				if (this.fullscreenLoading) return
				this.fullscreenLoading = true;
				let gift = this.centerInfo.infos[this.conIndex].id

				this.$req({
					method: 'post',
					data: {
						id: gift,
						address_id: this.address.id,
						pay_type: this.payWay,
						trade_type: 3, 
						upgrade:this.centerInfo.infos[this.conIndex].upgrade, 
					},
					url: '/api/vip/order',
					success: res => {
						this.fullscreenLoading = false;
						this.speedIndex = 3;
						this.$message.success('支付成功')

					},
					fail: error => {
						let url = error.data.data.code_url; // 微信支付链接
						if (error.data.code == 2001) {
							this.order_id = error.data.data.order_id
							if (this.payWay == 1) {
								this.dialogFormVisible = true;
								window.requestAnimationFrame(() => {
									this.fullscreenLoading = false;
									console.log(this.$refs)
									this.$refs.payqrcode.innerHTML = '';
									// 支付二维码
									let qrcode = new QRCode(this.$refs.payqrcode, {
										text: url, // 需要转换为二维码的内容
										width: 150,
										height: 150,
										colorDark: '#000000',
										colorLight: '#ffffff',
										correctLevel: QRCode.CorrectLevel.H
									})
									this.getOrderStatus(); // 获取订单详情
								})
							} else {
								this.fullscreenLoading = false;
								let html = error.data.data;
								const div = document.createElement('divform');
								div.innerHTML = html;
								document.body.appendChild(div);
								// document.forms[0].acceptCharset='GBK'; //保持与支付宝默认编码格式一致，如果不一致将会出现：调试错误，请回到请求来源地，重新发起请求，错误代码 invalid-signature 错误原因: 验签出错，建议检查签名字符串或签名私钥与应用公钥是否匹配
								document.forms[0].submit();

							}

						}
					},


				});
			},
			getOrderStatus() {

				this.$req({
					method: 'get',
					url: '/api/mapleOrder/detail',
					data: {
						id: this.order_id
					},
					success: res => {
						let data = res;
						if (data.status > 1) {
							this.$message.success("支付成功");
							this.dialogFormVisible = false;
							this.speedIndex = 3;
							window.requestAnimationFrame(() => {
								this.request.tokenSuccess();
							})

						} else if (data.status < 0) {
							this.dialogFormVisible = false;
							this.$message.warning("订单已取消");
						} else if (this.dialogFormVisible) {
							let a = setTimeout(() => {
								clearTimeout(a);
								this.getOrderStatus(); // 获取订单详情
							}, 2000)
						}
					},
					fail: error => {

					}
				});
			},
			Increaseaddress(type, item) {
				this.addressInfo = true;
				this.fulltype = type;
				if (this.fulltype == 1) {
					this.name = '';
					this.phone = '';
					this.areaId.province_id = '';
					this.areaId.district_id = '';
					this.areaId.district_id = '';
					this.region = [];
					this.addressDesc = '';
					this.setupDefault = 0;
					this.addressId = '';
				} else {
					this.edit(item);
				}
			},
			// 选择地址
			chooseAddressInfo(e) {
				console.log(e, this.address)
				this.address.address = this.list[e].full_address
				this.address.id = this.list[e].id
				this.address.name = this.list[e].name;
				this.address.phone = this.list[e].phone;
				this.iSelected = this.list[e].id
			},
			setUpDefaults(e, id) {
				this.$req({
					method: 'put',
					data: {
						id: id,
					},
					url: '/api/maple/addressDefault',
					success: res => {
						this.$message.success('设置成功');
						this.getData();
						this.list.forEach(item => {
							if (item.is_default == 1) {
								item.is_default = 0;
							}
						})
						this.list[e].is_default = 1;

					},

				});
			},

			// 是否默认
			defaultChoose() {
				if (this.setupDefault == 1) {
					this.setupDefault = 0
				} else {
					this.setupDefault = 1
				}
			},
			// 省市区选择
			addressTypeChoose(e) {
				this.addressShowType = e;
			},
			//打开地址列表
			addresShow() {
				this.showAddresstow = !this.showAddresstow;
			},
			chooseAddress(item) {
				this.addresslist = []
				if (this.addressShowType == 1) {
					this.region[0] = item.name;
					this.areaId.province_id = item.id
					this.addresslist = item.children
				}
				if (this.addressShowType == 2) {
					this.region[1] = item.name;
					this.areaId.city_id = item.id
					this.addresslist = item.children
				}
				if (this.addressShowType == 3) {
					this.region[2] = item.name;
					this.areaId.district_id = item.id
					this.showAddresstow = !this.showAddresstow;

				}
				if (this.showAddresstow) {
					this.addressShowType++;
				} else {
					this.addressShowType = 1;
					this.addresslist = this.provinceData;
				}

			},
			// 保存
			save() {
				if (!this.name) {
					this.$message.error('请输入姓名');
					return false
				}
				if (!this.region) {
					this.$message.error('请选择省市区');
					return false
				}
				let phone = /^1[3456789]\d{9}$/;
				if (!phone.test(this.phone, "phone")) {
					this.$message.error("请输入正确的手机号")
					return false;
				}
				if (!this.addressDesc) {
					this.$message.error('请输入详细地址');
					return false
				}
				let method = this.fulltype == 1 ? 'post' : 'put',
					obj = {}
				if (this.addressId) {
					method = 'PUT'
					obj = {
						id: this.addressId
					}
				}
				this.$req({
					method: method,
					data: {
						name: this.name,
						phone: this.phone,
						...this.areaId,
						address: this.addressDesc,
						is_default: this.setupDefault ? 1 : 0,
						...obj,
					},
					url: '/api/maple/address',
					success: res => {
						this.$message.success(this.fulltype == 1 ? '添加成功' : '保存成功')
						this.list = [];
						this.getData();
						this.cancel();
						this.$router.go(0)
					},
					fail: error => {

					}
				});
			},
			edit(item) {
				this.name = item.name
				this.phone = item.phone
				this.region[0] = item.city_text;
				this.addressId = item.id
				this.addressDesc = item.address
				this.areaId = {
					province_id: item.province_id,
					city_id: item.city_id,
					district_id: item.district_id,
				}
				this.setupDefault = item.is_default
			},
			// 取消
			cancel() {
				this.addressInfo = false;
			},
			// 删除
			delItem(e) {
				this.delIndex = e;
				this.$refs.SureCancelPopup.open();

			},
			sureBtn() {
				this.$req({
					method: 'delete',
					data: {
						ids: [this.list[this.delIndex].id],
					},
					url: '/api/maple/address',
					success: res => {
						this.list.splice(this.delIndex, 1);
						this.$message.success('删除成功');
						this.$refs.SureCancelPopup.close();
						this.$router.go(0)
					},
				});
			},
		}
	}
</script>

<style lang="less" scoped>
	.save-area {
		bottom: 30px;
	}

	.btn-02 {
		width: 160px;
		height: 50px;
		background: #DCFF03;
		border-radius: 25px;
	}

	.puops {
		display: flex;
		align-items: center;
		min-height: 316px;
		flex-direction: column;

		h2 {
			width: 100%;
			text-align: center;
			font-size: 20px;
			font-weight: 500;
			color: #131313;
			border-bottom: 1px solid #d7dae2;
			padding-bottom: 22.5px;
		}

		.order_pay {
			display: flex;
			flex-direction: column;
			background-color: #e5e5e5;
			width: 100%;
			padding: 21px 35px;
		}

		.qrcode-box {
			margin: 40px 0;
			width: 150px;
			height: 150px;
			background-color: #f9f9f9;
			overflow: hidden;

			canvas+img {
				width: 100%;
				height: 100%;
			}
		}

		p {
			font-size: 19px;
			font-weight: 500;
			color: #E84516;
			opacity: 0.5;
		}
	}

	.scroll_single {
		display: flex;
		width: 186px;
		height: 241px;
		background: #FFFFFF;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
		border-radius: 16px;
	}

	.single {
		display: inline-block;
		margin-right: 25px;
		box-sizing: border-box;
	}

	.active {
		border: 3px solid #010085;
	}

	.tab_box {
		position: absolute;
		top: 0;
		right: 0;
		/* padding: 0 20rpx; */
		// width: 86px;

		height: 34px;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		box-sizing: border-box;
		background-color: #DCFF03;
		border-radius: 0px 13px 0px 13px;
		padding: 7px 10px;
	}

	.single_cover {
		width: 100%;
		height: 140px;
		border-radius: 16px 16px 0 0;
	}

	.speed-line {
		width: 581px;

		.speed-left {
			width: 210px;
			height: 10px;
			border-radius: 6px;
		}

		.speed-num {
			width: 30px;
			height: 30px;
		}
	}

	.choose-icon {
		width: 21px;
		height: 21px;
	}

	.default-btn {
		width: 64px;
		height: 24px;
		background: #DCFF03;
		border-radius: 2px;
	}

	.address-box {
		transition: all 0.3s;
	}

	.address_pull {
		width: 10px;
		height: 10px;
		transition: all 0.3s;
	}

	.rotate {
		transform: rotate(180deg);
	}

	.pay-shoose {
		width: 112px;
		height: 32px;
		background: #FFFFFF;
		border: 1px solid #CCCCCC;

		.sub_02 {
			width: 20px;
			height: 20px;
		}
	}

	.sub-03 {
		width: 21.71px;
		height: 21.71px;
	}

	.pay-shoose-active {
		border: 1px solid #DCFF03;
	}

	.img-02 {
		width: 96px;
		height: 96px;
		border-radius: 4px;
	}

	.submit-btn {
		width: 135px;
		height: 36px;
		background: #DCFF03;
		border-radius: 2px;
	}

	.cou-top {
		width: 204px;
		height: 70px;
		background: url(../../assets/img/sub_04.png) no-repeat center;
		background-size: 100% 100%;
	}

	.cou-bottom {
		width: 202px;
		height: 32px;
		border: 1px solid #DCFF03;
	}

	.popup-name {
		width: 80px;
	}

	.address_pull {
		width: 10px;
		height: 10px;
		transition: all 0.3s;
	}

	.address-area-box {
		overflow: hidden;
		transition: all 0.3s;

		.address-area {
			border: 1px solid #E6E6E6;

			.city-item:hover {
				cursor: pointer;
				background: rgba(220, 255, 3, 0.2);
			}
		}
	}

	.textarea-popup {
		padding: 12px;
		width: 338px;
		height: 59px;
		background: #FFFFFF;
		border: 1px solid #CCCCCC;
		resize: none;
		outline: none;
	}

	.is_choose {
		width: 18px;
		height: 18px;
	}

	.btn-popup-01 {
		width: 72px;
		height: 28px;
		background: #DCFF03;
		border-radius: 3px;
	}

	.btn-popup-02 {
		width: 70px;
		height: 26px;
		border: 1px solid #999999;
		border-radius: 3px;
	}

	.el-overlay {}

	.input-popup-area {
		padding: 0 16px;
		width: 330px;
		height: 32px;
		background: #FFFFFF;
		border: 1px solid #CCCCCC;
	}

	.input-popup {
		outline: none;
		border: 0;
		padding: 0;
		margin: 0;
	}

	.el-dialog--center .el-dialog__body {
		padding: 0;
	}

	.el-dialog__header {
		padding: 0;
	}

	.el-dialog {
		margin-bottom: 0;
	}

	.el-overlay {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.set-up-box {
		display: none;
	}

	.sure-address-item:hover {
		cursor: pointer;
		background: rgba(220, 255, 3, 0.2);
	}

	.sure-address-item:hover .set-up-box {
		display: flex;
	}

	.sub_success {
		width: 142px;
		height: 134px;
	}

	.z100 {
		z-index: 1;
	}
</style>
